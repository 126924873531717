@font-face {
  font-family: "ExpoArabic Bold";
  src: url("./fonts/ExpoArabic-Bold.otf") format("opentype");
}

@font-face {
  font-family: "ExpoArabic Book";
  src: url("./fonts/ExpoArabic-Book.woff2") format("woff2"),
    url("./fonts/ExpoArabic-Book.ttf") format("truetype");
}

@font-face {
  font-family: "ExpoArabic Light";
  src: url("./fonts/ExpoArabic-Light.woff2") format("woff2"),
    url("./fonts/ExpoArabic-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ExpoArabic Medium";
  src: url("./fonts/ExpoArabic-Medium.woff2") format("woff2"),
    url("./fonts/ExpoArabic-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "ExpoArabic SemiBold";
  src: url("./fonts/ExpoArabic-SemiBold.woff2") format("woff2"),
    url("./fonts/ExpoArabic-Medium.ttf") format("truetype");
}
