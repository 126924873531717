.ism-nav__tab {
  display: inline-block;
  padding: 0 1.8rem;
  border-radius: 5px 5px 0 0;
  background: #0050ff;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 4.7rem;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .ism-nav__tab {
    padding-inline-end: 2rem;
    padding-inline-start: 2rem;
  }
}

.ism-nav__tab.active {
  color: #131924;
  background: #ffffff;
}

.ism-nav__tab.isAlt {
  background-color: #ffd600;
  color: #0e204d;
  text-transform: lowercase;
}
